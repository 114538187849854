<template>
  <div id="text">
    <el-form @submit.native.prevent ref="form-text">
      <el-collapse>
        <p><small>Click text to add to the chart</small></p>
        <el-form-item class="item-h1 full-width">
          <el-button
            @click="addText('Add a heading', 42)"
            class="text"
            type="plain"
            ><h1>Add a heading</h1></el-button
          >
        </el-form-item>
        <el-form-item class="item-h2 full-width">
          <el-button
            @click="addText('Add a subheading', 34)"
            class="text"
            type="plain"
            ><h2>Add a subheading</h2></el-button
          >
        </el-form-item>
        <el-form-item class="item-p full-width">
          <el-button
            @click="addText('Add body text', 21)"
            class="text"
            type="plain"
            >Add body text</el-button
          >
        </el-form-item>
      </el-collapse>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      fontFamily: undefined,
    };
  },
  computed: {
    ...mapActions("elementsModule", [
      "addTextElement",
      "changeActiveElement",
      "changeActiveChart",
    ]),
  },
  methods: {
    addText(text, size) {
      this.$store
        .dispatch("elementsModule/addTextElement", { text, size })
        .then((textEl) => {
          this.$store
            .dispatch("elementsModule/changeActiveElement", { id: textEl.id })
            .then((response) => {
              this.$store.dispatch("elementsModule/changeActiveChart", {
                id: null,
              });
            })
            .catch((e) => {
              console.error(e);
            });
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.item-h1,
.item-h2,
.item-p {
  height: 80px;

  h1,
  h2 {
    margin: 0;
    font-weight: 700;
  }
}
.text {
  height: 70px;
  line-height: 60px;
  padding: 0;
  color: #fff;
  width: 100%;
  display: block;
  border: 0;
  background: darken($color-blue-grey-dark, 1%);
}
</style>
